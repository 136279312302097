import React from 'react';
import { Carousel } from 'react-bootstrap';
import projetsData from '../../containers/archives/Archives.json';
import images from '../../assets/img/projets/images';
import 'bootstrap/dist/css/bootstrap.min.css';

const ArchivesImages = () => {
  return (
    <Carousel>
      {projetsData.pics.map((pic, index) => (
        <Carousel.Item key={index}>
          <img
            className="d-block w-100"
            src={images[pic.url]} // Adapté pour correspondre à votre structure des images
            alt={pic.altText}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ArchivesImages;
