import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import images from '../../assets/img/projets/images';  
import 'bootstrap/dist/css/bootstrap.min.css';
import './articleSlider.css';

const MainSwiper = ({ pictures }) => {
  return (
    <Carousel interval={5000} indicators={true} controls={true}>
      {pictures.map((pic, index) => (
        <Carousel.Item key={index}>
          <img
            className="d-block w-100"
            src={images[pic]}
            alt={`Slide ${index}`}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default MainSwiper;
