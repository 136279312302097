import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/logo.webp';
import './navbar.css';

const CustomNavbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const MobileMenuButton = () => (
    <button
      className="mobile-menu-button"
      onClick={toggleMobileMenu}
      aria-label="Toggle menu"
    >
      {mobileMenuOpen ? <RiCloseLine /> : <RiMenu3Line />}
    </button>
  );

  return (
    <Navbar bg="white" expand="lg" fixed="top">
      <Navbar.Brand href="/">
        <img
          src={logo}
          width="30"
          height="30"
          className="d-inline-block align-top"
          alt="Logo"
        />
        {' '}
      </Navbar.Brand>
      <MobileMenuButton />
      <Navbar.Collapse
        id="basic-navbar-nav"
        className={`collapse ${mobileMenuOpen ? 'show' : ''}`}
      >
        <Nav className="ml-auto">
        <Link to="/" className="links">Accueil</Link>
          <Link to="/agence" className="links">Agence</Link>
          <Link to="/actualites" className="links">Actualités</Link>
          <Link to="/projets" className="links projectslinks">Projets</Link>
          <Link to="/archives" className="links">Archives</Link>
          <Link to="/contact" className="links">Contact</Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;