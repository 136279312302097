import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import images from '../../assets/img/projets/images';
import projetsData from '../../containers/projet/projets.json';
import 'bootstrap/dist/css/bootstrap.min.css';

const ArticleSlider = () => {
  const [indexes, setIndexes] = useState([]);

  useEffect(() => {
    const randomIndexes = new Set();

    while (randomIndexes.size < 5) {
      randomIndexes.add(Math.floor(Math.random() * projetsData.length));
    }

    setIndexes([...randomIndexes]);
  }, []);

  return (
    <Carousel>
      {indexes.map((i) => (
        <Carousel.Item key={i}>
          <img
            className="d-block w-100"
            src={images[projetsData[i].image.publicUrl]}
            alt={projetsData[i].titre}
            onClick={() => window.open(projetsData[i].linkDemo)}
            style={{ cursor: 'pointer' }}
          />
          <Carousel.Caption>
            <h3>{projetsData[i].titre}</h3>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ArticleSlider;
