import React from 'react'

const formatBudget = (budget) => {
    return budget ? budget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : 'NC';
};

const MainDescription = ({ projet }) => {
    if (!projet) return null; // Vérification que le projet existe

    return (
        <div className='projet-info panel widget'>
            <b>Projet réalisé</b><br/>
            {projet.endPeriod}<br/>
            <b>Surface plancher</b><br/>
            {projet.area ? `${formatBudget(projet.area)} m²` : 'NC'}<br/>
            <b>Montant des travaux</b><br/>
            {projet.budget ? `${formatBudget(projet.budget)} € HT` : 'NC'}<br/>
            <b>Caractéristique de l'opération</b><br/>
            {projet.caracteristics ? projet.caracteristics.map(caracteristic => <div key={caracteristic}>{caracteristic}</div>) : 'NC'}
            <b>Maitre d'ouvrage</b><br/>
            {projet.projectOwner || 'NC'}<br/>
        </div>
    )
}

export default MainDescription
